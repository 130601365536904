<template>
  <el-container class="h-100">
    <meta name="viewport" content="width=device-width,user-scalable=no,initial-scale=1.0">
    <div class="h-100 w-500">
      <header class="h5_header">
        <h1>{{ h5Data.doc_header.doc_title }}</h1>
        <p>公司：{{ h5Data.doc_header.company_name }}</p>
        <p>数据范围：{{ h5Data.doc_header.doc_date }}</p>
      </header>
      <div class="h5_nav  mar-t-15">
        <a @click="handleClick('gs')" class="active">概述</a>
        <a @click="handleClick('jczl')">监测总览</a>
        <a @click="handleClick('zdxxzy')">重点信息摘要</a>
        <a @click="handleClick('xxylb')">信息一览表</a>
      </div>
      <div v-if="activeIndex == 'gs'" class="gs_cont">
        <div class="h5_card">
          <h3>本次日报收集了</h3>
          <p>{{ h5Data.doc_summary.span[0] }}至{{ h5Data.doc_summary.span[1] }}期间撷取于各大网站及社交平台的{{ h5Data.doc_summary.count
            }}条重点信息。</p>
        </div>
        <div class="t-c" id="h5-parent">
          <img src="@/assets/images/common/banner@3x.png" width="100%">
        </div>
      </div>
      <div v-if="activeIndex == 'jczl'" class="jczl_cont">
        <div class="h5_card">
          <h3>（一）信息总量走势</h3>
          <p style="margin-bottom: 10px;">本次的{{ h5Data.diagram_info1_header.total }}条重点信息，发布高峰为{{
            h5Data.diagram_info1_header.span[0] }} 至{{ h5Data.diagram_info1_header.span[1] }}的时段，共{{
            h5Data.diagram_info1_header.max }}条信息，占总量中的{{ h5Data.diagram_info1_header.rate }}%。</p>
          <h2 class="tit"><span>信息声量走势</span></h2>
          <div class="map" id="voicetrend">
          </div>
        </div>
        <div class="h5_card mar-t-15">
          <h3>（二）敏感信息走势</h3>
          <p>
            本次的{{ h5Data.diagram_info2_header.total }}条重点信息，
            敏感信息共{{ h5Data.diagram_info2_header.negative_count }}条，占总量中的{{ h5Data.diagram_info2_header.negative_rate
            }}%。
            需特别关注"{{ h5Data.diagram_info2_header.follow }}"的信息。
            从走势来看，发布高峰为{{ h5Data.diagram_info2_header.negative_max_span[0] }} 至 {{
            h5Data.diagram_info2_header.negative_max_span[1] }}的时段，
            共{{ h5Data.diagram_info2_header.negative_max }}条，占所有敏感信息的{{ h5Data.diagram_info2_header.negative_max_rate
            }}%，高峰时间与整体信息的声量走势一致。
          </p>
          <div class="h5_card mar-t-15">
            <h2 class="tit"><span>情感声量走势</span></h2>
            <div class="map" id="emotiontrend">
            </div>
          </div>
          <div class="h5_card mar-t-15">
            <h2 class="tit"><span>敏感信息占比</span></h2>
            <div class="map" id="sensitive">
            </div>
          </div>
        </div>
        <div class="h5_card mar-t-15">
          <h3>（三）信息来源分布</h3>
          <p>
                <span v-for="(item, index) in h5Data.diagram_info3_1" :key="index">
                  来自{{ item.name }}的共{{ item.num }}条,占{{ item.rate }}%。
                </span>
            其中最大的来源分别为： {{ h5Data.diagram_info3_header.media_top3.join("、") }}。
          </p>
          <div class="h5_card mar-t-15">
            <h2 class="tit"><span>信息来源分布</span></h2>
            <div class="map" id="mediaSourceMap" v-show="h5Data.diagram_info3_1.length >= 1">
            </div>
            <div class="echarts_empty" v-show="h5Data.diagram_info3_1.length < 1">
              <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
            </div>
          </div>
          <div class="h5_card mar-t-15">
            <h2 class="tit"><span class="cor_f00">Top10</span> 主要媒体来源</h2>
            <div class="w-100 mar-t-15">
              <ul class="nav nav-pills">
                <li><a>网媒</a></li>
                <li class="active"><a>微博</a></li>
                <li><a>微信</a></li>
                <li><a>论坛</a></li>
              </ul>
            </div>
            <div class="tab-content mtly" v-show="h5Data.diagram_info3_2.length >= 1">
              <ul>
                <li>
                  <h5><span class="num_tit">序号</span><span>媒体</span><span class="redu">条数</span><span
                          class="redu">占比</span></h5>
                </li>
                <li v-for="(row, index) in h5Data.diagram_info3_2" :key="index">
                  <span class="num" v-if="index == 0">1</span>
                  <span class="num bg_ec7" v-if="index == 1">2</span>
                  <span class="num bg_ffb" v-if="index == 2">3</span>
                  <span class="num bg_2a6" v-if="index >= 3">{{ index + 1 }}</span>
                  <a>{{ row.name }}</a>
                  <div class="progressBar">
                    <dl class="barbox">
                      <dd class="barline">
                        <div class="charts" :style="row.style"></div>
                      </dd>
                    </dl>
                  </div>
                  <span class="redu">{{ row.num }}条</span>
                  <span class="redu">{{ row.rate }}%</span>
                </li>
              </ul>
            </div>
            <div class="echarts_empty" v-show="h5Data.diagram_info3_2.length < 1">
              <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
            </div>
          </div>
          <div class="h5_card mar-t-15">
            <h2 class="tit"><span class="cor_f00">Top10</span> 主要媒体来源</h2>
            <div class="w-100 mar-t-15">
              <ul class="nav nav-pills">
                <li class="active"><a>网媒</a></li>
                <li><a>微博</a></li>
                <li><a>微信</a></li>
                <li><a>论坛</a></li>
              </ul>
            </div>
            <div class="tab-content mtly" v-show="h5Data.diagram_info3_3.length >= 1">
              <ul>
                <li>
                  <h5><span class="num_tit">序号</span><span>媒体</span><span class="redu">条数</span><span
                          class="redu">占比</span></h5>
                </li>
                <li v-for="(row, index) in h5Data.diagram_info3_3" :key="index">
                  <span class="num" v-if="index == 0">1</span>
                  <span class="num bg_ec7" v-if="index == 1">2</span>
                  <span class="num bg_ffb" v-if="index == 2">3</span>
                  <span class="num bg_2a6" v-if="index >= 3">{{ index + 1 }}</span>
                  <a>{{ row.name }}</a>
                  <div class="progressBar">
                    <dl class="barbox">
                      <dd class="barline">
                        <div class="charts" :style="row.style"></div>
                      </dd>
                    </dl>
                  </div>
                  <span class="redu">{{ row.num }}条</span>
                  <span class="redu">{{ row.rate }}%</span>
                </li>
              </ul>
            </div>
            <div class="echarts_empty" v-show="h5Data.diagram_info3_3.length < 1">
              <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
            </div>
          </div>
          <div class="h5_card mar-t-15">
            <h2 class="tit"><span class="cor_f00">Top10</span> 主要媒体来源</h2>
            <div class="w-100 mar-t-15">
              <ul class="nav nav-pills">
                <li><a>网媒</a></li>
                <li><a>微博</a></li>
                <li class="active"><a>微信</a></li>
                <li><a>论坛</a></li>
              </ul>
            </div>
            <div class="tab-content mtly" v-show="h5Data.diagram_info3_4.length >= 1">
              <ul>
                <li>
                  <h5><span class="num_tit">序号</span><span>媒体</span><span class="redu">条数</span><span
                          class="redu">占比</span></h5>
                </li>
                <li v-for="(row, index) in h5Data.diagram_info3_4" :key="index">
                  <span class="num" v-if="index == 0">1</span>
                  <span class="num bg_ec7" v-if="index == 1">2</span>
                  <span class="num bg_ffb" v-if="index == 2">3</span>
                  <span class="num bg_2a6" v-if="index >= 3">{{ index + 1 }}</span>
                  <a>{{ row.name }}</a>
                  <div class="progressBar">
                    <dl class="barbox">
                      <dd class="barline">
                        <div class="charts" :style="row.style"></div>
                      </dd>
                    </dl>
                  </div>
                  <span class="redu">{{ row.num }}条</span>
                  <span class="redu">{{ row.rate }}%</span>
                </li>
              </ul>
            </div>
            <div class="echarts_empty" v-show="h5Data.diagram_info3_4.length < 1">
              <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
            </div>
          </div>
        </div>
        <div class="h5_card mar-t-15">
          <h3>（四）信息地区分布</h3>
          <p>
            本次的{{ h5Data.diagram_info4_header.total }}条重点信息，
            主要分布于：{{ h5Data.diagram_info4_header.province_distribution.join("、") }}。
            敏感信息则主要分布于：{{ h5Data.diagram_info4_header.province_distribution_sensitive.join("、") }}，
            与总量的分布{{ identical ? '一致' : '不一致' }}。
          </p>
          <div class="h5_card mar-t-15">
            <h2 class="tit"><span class="cor_f00">Top10</span> 主要发布地区</h2>
            <div class="w-100 mar-t-15" v-show="h5Data.diagram_info4_1.length >= 1">
              <ul class="nav nav-pills">
                <li class="active"><a>非敏感</a></li>
                <li><a>敏感</a></li>
              </ul>
            </div>
            <div class="tab-content mtly" v-show="h5Data.diagram_info4_1.length >= 1">
              <ul>
                <li>
                  <h5><span class="num_tit">序号</span><span>地区</span><span class="redu">条数</span><span
                          class="redu">占比</span></h5>
                </li>
                <li v-for="(row, index) in h5Data.diagram_info4_1" :key="index">
                  <span class="num" v-if="index == 0">1</span>
                  <span class="num bg_ec7" v-if="index == 1">2</span>
                  <span class="num bg_ffb" v-if="index == 2">3</span>
                  <span class="num bg_2a6" v-if="index >= 3">{{ index + 1 }}</span>
                  <a>{{ row.name }}</a>
                  <div class="progressBar">
                    <dl class="barbox">
                      <dd class="barline">
                        <div class="charts" :style="row.style"></div>
                      </dd>
                    </dl>
                  </div>
                  <span class="redu">{{ row.num }}条</span>
                  <span class="redu">{{ row.rate }}%</span>
                </li>
              </ul>
            </div>
            <div class="echarts_empty" v-show="h5Data.diagram_info4_1.length < 1">
              <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
            </div>
          </div>
          <div class="h5_card mar-t-15">
            <h2 class="tit"><span class="cor_f00">Top10</span> 主要发布地区</h2>
            <div class="w-100 mar-t-15" v-show="h5Data.diagram_info4_2.length >= 1">
              <ul class="nav nav-pills">
                <li><a>非敏感</a></li>
                <li><a style="background-color: red;color: white;">敏感</a></li>
              </ul>
            </div>
            <div class="tab-content mtly top_mtly" v-show="h5Data.diagram_info4_2.length >= 1">
              <ul>
                <li>
                  <h5><span class="num_tit">序号</span><span>地区</span><span class="redu">条数</span><span
                          class="redu">占比</span></h5>
                </li>
                <li v-for="(row, index) in h5Data.diagram_info4_2" :key="index">
                  <span class="num" v-if="index == 0">1</span>
                  <span class="num bg_ec7" v-if="index == 1">2</span>
                  <span class="num bg_ffb" v-if="index == 2">3</span>
                  <span class="num bg_2a6" v-if="index >= 3">{{ index + 1 }}</span>
                  <a>{{ row.name }}</a>
                  <div class="progressBar">
                    <dl class="barbox">
                      <dd class="barline">
                        <div class="charts" :style="row.style"></div>
                      </dd>
                    </dl>
                  </div>
                  <span class="redu">{{ row.num }}条</span>
                  <span class="redu">{{ row.rate }}%</span>
                </li>
              </ul>
            </div>
            <div class="echarts_empty" v-show="h5Data.diagram_info4_2.length < 1">
              <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeIndex == 'zdxxzy'" class="zdxxzy_cont">
        <p class="zyxx">本次简报{{ h5Data.doc_summary.count }}条重点信息中最重要的{{ h5Data.news_list.length }}条如下。</p>
        <div class="h5_card" v-for="(row, index) in h5Data.news_list" :key="index" style="margin-bottom: 10px;">
          <h4>
            {{ index + 1 }}.
            <span class="fmg" v-if="row.emotion == 'positive'">非敏感</span>
            <span class="mg" v-else>敏感</span>
            {{ row.title }}
          </h4>
          <p class="fbsj">
            <span>发布时间：{{ row.published }}</span>
            <span>媒体：{{ row.category }}</span>
            <span>来源：{{ (row.category == "微博" || row.category == "微信") ? row.author : row.source_name }}</span>
          </p>
          <p class="summary">摘要：{{ row.content.substr(0, 50) }}</p>
          <a :href="row.source_url" target="_blank" class="more">查看原文 &gt;&gt;</a>
        </div>
      </div>
      <div v-if="activeIndex == 'xxylb'" class="zdxxzy_cont">
        <div class="h5_card" v-for="(item, index) in h5Data.message_list" :key="index" style="margin-bottom: 10px;">
          <h4>
            {{ index + 1 }}.
            <span class="mg" v-if="item.emotion == 'negative'">敏感</span>
            <span class="fmg" v-else>非敏感</span>
            {{ item.title }}
          </h4>
          <p class="fbsj">
            <span>发布时间：{{ item.published }}</span>
            <span>媒体：{{ item.category }}</span>
            <span>来源：{{ (item.category == "微博" || item.category == "微信") ? item.author : item.site }}</span>
          </p>
          <p class="summary" v-if="item.description">摘要：{{ item.description }}</p>
          <a :href="item.source_url" target="_blank" class="more">查看原文 &gt;&gt;</a>
        </div>
      </div>
      <footer class="h5_footer" v-if="version === 'common'">数知舆情管理系统 版权所有</footer>
    </div>
  </el-container>
</template>
<script>
  import {items} from "@/api/briefing";
  import { globalGetEcharts, globalCreateChart, globalMediaColor } from "@/utils/helpers";
  import { detail } from "@/api/autobrief";
  export default {
    name: "component-briefh5",
    components: {},
    data() {
      return {
        activeIndex: "gs",
        h5Data: {},
        list: {data: [], hasMore: true, page: 0, loading: false},
        identical: false,
        version: process.env.VUE_APP_VERSION
      }
    },
    mounted() {
      this.loadData();
    },
    methods: {
      loadData() {
        const {id} = this.$route.query;
        if (!id) {
          this.$message.error('不存在该简报');
          return false;
        }
        detail({ id }).then(res => {
          const {state, data} = res.data;
          if (state) {
            this.h5Data = data.result;
            this.handleData()
          } else {
            this.$message.error(res.data.error)
          }
        }).catch(err => {
          window.console.error(err)
        })
      },
      moreList() {
        let id = this.$route.query.id
        let page = this.list.page + 1
        let params = {id: id, page: page}
        this.list.loading = true
        items(params).then(res => {
          if (res.data.state) {
            let result = res.data.data
            if (result.size == 0) {
              this.list.hasMore = false
            } else {
              this.list.data = this.list.data.concat(result.data)
              this.list.page = result.page
            }
            this.list.loading = false
          } else {
            this.$message.error(res.data.error)
          }
        }).catch(res => {
          this.$message.error("加载列表失败，服务错误")
        })
      },
      handleData() {
        var data = this.h5Data
        _(data.diagram_info3_2).forEach((item, i) => {
          data.diagram_info3_2[i].style = "width:" + data.diagram_info3_2[i].rate + "%;"
        })
        _(data.diagram_info3_3).forEach((item, i) => {
          data.diagram_info3_3[i].style = "width:" + data.diagram_info3_3[i].rate + "%;"
        })
        _(data.diagram_info3_4).forEach((item, i) => {
          data.diagram_info3_4[i].style = "width:" + data.diagram_info3_4[i].rate + "%;"
        })
        _(data.diagram_info4_1).forEach((item, i) => {
          data.diagram_info4_1[i].style = "width:" + data.diagram_info4_1[i].rate + "%;"
        })
        _(data.diagram_info4_2).forEach((item, i) => {
          data.diagram_info4_2[i].style = "width:" + data.diagram_info4_2[i].rate + "%;"
        })
        this.h5Data = data
        let _this = this
        let sensitiveArea = this.h5Data.diagram_info4_header.province_distribution_sensitive.filter((area) => {
          return _this.h5Data.diagram_info4_header.province_distribution.includes(area)
        })
        this.identical = sensitiveArea.length == this.h5Data.diagram_info4_header.province_distribution.length ? true : false
      },
      makeMap() {
        var _this = this
        _this.$nextTick(() => {
          _this.voicetrend()
          _this.emotiontrend()
          _this.sensitive()
          _this.source()
        })
      },
      voicetrend() {
        var _this = this
        let element = window.document.getElementById('voicetrend')
        let option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            top: "45px",
            data: _this.h5Data.diagram_info1.medias
          },
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 100
            }
          ],
          grid: {
            left: '43px',
            right: '62px',
            top: '80px',
            bottom: '40px',
            containLabel: true
          },
          toolbox: {
            show: true,
            feature: {},
            right: "3%",
            top: "10px"
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: _this.h5Data.diagram_info1.keys
          },
          yAxis: {
            type: 'value'
          },
          color: ['#4fcfb0', '#238fff', '#ff4f65', '#f98d3b', '#febd2e', '#7bbdff', '#2ecd42', '#7a5f08', '#95bedd', '#8e8e8e'],
          series: _this.h5Data.diagram_info1.data
        }
        var myChart = globalCreateChart(element, option)
        this.$nextTick(() => {
          var parent = window.document.getElementById("voicetrend")
          var child = parent.getElementsByTagName("div")
          window.jQuery(child).css({display: "block"})
        })
      },
      emotiontrend() {
        var _this = this
        var element = window.document.getElementById('emotiontrend')
        var echarts = globalGetEcharts()
        let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          legend: {
            data: ['敏感', '非敏感'],
            top: "45px",
          },
          grid: {
            left: '43px',
            right: '62px',
            top: '80px',
            bottom: '40px',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: _this.h5Data.diagram_info2_1.keys
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 100
            }
          ],
          color: ['#ff4f65', '#238fff'],
          series: [
            {
              name: '敏感',
              type: 'line',
//             stack: '总量',
              data: _this.h5Data.diagram_info2_1.negative,
              smooth: true,
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#ff4f65'
                  }, {
                    offset: 1,
                    color: '#fff'
                  }])
                }
              },
            },
            {
              name: '非敏感',
              type: 'line',
//             stack: '总量',
              data: _this.h5Data.diagram_info2_1.positive,
              smooth: true,
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#238fff'
                  }, {
                    offset: 1,
                    color: '#fff'
                  }])
                }
              },
            }
          ]
        }
        var myChart = globalCreateChart(element, option)
      },
      sensitive() {
        var _this = this
        let element = window.document.getElementById('sensitive')
        let option = {
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b}: ({d}%)"
          },
          toolbox: {
            show: true,
            feature: {},
            right: "3%",
            top: "10px"
          },
          legend: {
            // orient: 'vertical',
            // x: 'left',
            data: ['敏感', '非敏感'],
            top: "38px",
            formatter: function (name) {
              return name == "敏感" ? "敏感 " + _this.h5Data.diagram_info2_2.negative + "%" : "非敏感 " + _this.h5Data.diagram_info2_2.positive + "%"
            }
          },
          color: ['#fd5066', '#009df6'],
          series: [
            {
              name: '情感分布',
              type: 'pie',
              center: ['50%', '60%'],
              radius: ['45%', '60%'],
              avoidLabelOverlap: false,
              label: {
                normal: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: '30',
                    fontWeight: 'bold'
                  }
                }
              },
              labelLine: {
                normal: {
                  show: false
                }
              },
              data: [{name: "敏感", value: _this.h5Data.diagram_info2_2.negative}, {
                name: "非敏感",
                value: _this.h5Data.diagram_info2_2.positive
              }]
            }
          ]
        }
        var myChart = globalCreateChart(element, option)
      },
      source() {
        var data = this.h5Data.diagram_info3_1
        var kMaps = {}
        var total = 0
        var keys = []
        data.map(function (item) {
          total += item.num
        })
        data = data.map(function (item) {
          var rate = (item.num / total) * 100
          rate = rate.toFixed(2).toString()
          kMaps[item.name] = rate + "% "
          keys.push(item.name)
          item.itemStyle = {color: globalMediaColor(item.name)}
          item.value = item.num
          return item
        })
        var option = {
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          legend: {
            orient: 'vertical',
            left: '10px',
            bottom: "20px",
            data: keys,
            icon: "circle",
            itemGap:5,
            itemWidth:10,
            itemHeight:10,
            formatter: function (name) {
              return name + " " + kMaps[name]
            }
          },
          series: [
            {
              name: '来源分布',
              type: 'pie',
              radius: ['45%', '60%'],
              center: ['50%', '50%'],
              data: data,
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                },
                normal: {
                  label: {
                    show: false
                  },
                  labelLine: {
                    show: false
                  }
                }
              }
            }
          ]
        };
        var element = window.document.getElementById('mediaSourceMap');
        if (!element) return false
        var myChart = globalCreateChart(element, option)
      },
      handleClick(index) {
        this.activeIndex = index
        let indexMap = ["gs", "jczl", "zdxxzy", "xxylb"]
        _(indexMap).forEach((v, k) => {
          window.jQuery(".h5_nav a").removeClass("active")
          if (v == index) {
            window.jQuery(".h5_nav a:eq(" + k + ")").addClass("active")
            return false
          }
        })
        switch (index) {
          case "jczl":
            this.makeMap()
            break
          case "xxylb":
            break
          case "gs":
            this.$nextTick(() => {
              var parent = window.document.getElementById("h5-parent")
              var child = parent.getElementsByTagName("div")
              window.jQuery(child).css({display: "none"})
            })
            break
        }
      }
    }
  }
</script>
<style scoped>
.map{height: 300px;}
.h5_header{background-image: linear-gradient(90deg, #009ef6 0%, #5060d6 100%);width:100%;text-align: center;overflow: hidden;color:#fff;padding:10px 0;}
.h5_header h1{font-size:15px;line-height:40px;color:#fff;}
.h5_header p{line-height:20px;font-size:12px;color:#fff;}
.h5_nav{margin:15px 15px 0;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;}
.h5_nav a{height:30px;line-height:30px;background:#e5e5e5;font-size:12px;border-radius:15px;color:rgba(0,0,0,.5);display: inline-block;padding:0 15px;cursor: pointer;}
.h5_nav a.active{color:#fff;background-image: linear-gradient(90deg, #009ef6 0%, #5060d6 100%);}
.gs_cont,.jczl_cont,.zdxxzy_cont{padding:15px;}
.h5_card{background-color: #ffffff;box-shadow:0px 2px 8px 0px rgba(194,194,194,0.2);border-radius:3px;padding: 15px;}
.h5_card h3,.h5_card p{font-size:12px;line-height:24px;color:rgba(1,1,1,.8)}
.h5_card h3,.tszl_cont dt{font-weight:600;}
.h5_card p{text-indent: 2em;}
.h5_card .tit {border-left:2px solid #555DFE;padding-left:15px;font-weight:600;font-size:14px;line-height:14px;color:#000;}
.h5_card .tit .cor_f00{color:red;}
.h5_card .nav-pills{margin:0.21rem auto 0;width:auto;float: none;text-align: center;}
.h5_card .nav-pills li{margin:0.21rem 0.08rem 0;display: inline-block;float: none;}
.h5_card .nav-pills li a{font-size:1rem;background:#e9e9e9;color:rgba(0,0,0,.47);padding: 0.25rem 1.125rem;border-radius:0.625rem;}
.h5_card .nav-pills > li.active > a,.h5_card  .nav-pills > li.active > a:hover,.h5_card .nav-pills > li.active > a:focus{color:#fff;background:#2a67b3;}
.mtly h5{font-size:14px;line-height: 30px;}
.mtly li{line-height:30px;font-size:12px;overflow: hidden;}
.mtly .num_tit{width:50px;float:left;}
.mtly .num{float: left;width:16px;height:16px;background:#d82008;margin:8px 34px 0 0;line-height:16px;color:#fff;text-align: center;font-size:12px;}
.mtly .bg_ffb{background: #ffb229;}
.mtly .bg_ec7{background: #ec753a;}
.mtly .bg_2a6{background:#2a67b3;}
.tab-content a{float:left;width:15%;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;font-size:12px;}
.mtly .redu{width:40px;text-align: center;float:right;font-size:12px;}
.progressBar{float: left;width:46%;margin-top:10px;}
.progressBar .barline{float: left;width: 100%;background: rgb(223, 223, 223);height:10px;overflow: hidden;position: relative;border-radius:5px;}
.progressBar .charts{width: 70%;background-image: linear-gradient(90deg,#555DFE 0%,#5660d2 100%),linear-gradient(#ffffff,#ffffff);background-blend-mode: normal,normal;height:10px;border-radius:5px;}
.top_mtly .nav-pills > li.active > a,.top_mtly .nav-pills > li.active > a:hover,.top_mtly .nav-pills > li.active > a:focus{color:#fff;background:#f00;}
.top_mtly .charts{background-image: linear-gradient(90deg, #dd897a 0%, #d33e5e 44%, #e11f37 83%, #ee000f 100%), linear-gradient(#96ddfe, #96ddfe);width:90%;}
/*重点信息摘要*/
.zdxxzy_cont p.zyxx{margin:0 15px;font-size:12px;line-height:30px;color:rgb(0,0,0);}
.zdxxzy_cont h4{font-size:12px;line-height:24px;color:rgb(0,0,0);font-weight:600;max-height:120px;display: -webkit-box !important;overflow: hidden;text-overflow: ellipsis;word-break: break-all;-webkit-box-orient:vertical;-webkit-line-clamp:2;}
.zdxxzy_cont h4 .mg,.zdxxzy_cont h4 .fmg{background: #f00;border-radius:3px;font-size:12px;padding:5px 10px;color: #fff;margin:0 5px;line-height:22px;}
.zdxxzy_cont h4 .fmg{background:#555DFE;}
.zdxxzy_cont .cor_006{color:#0068b7;}
.zdxxzy_cont .fbsj{line-height:30px;color:rgba(0,0,0,.8);font-size:12px;text-indent:0;}
.zdxxzy_cont .fbsj span{padding-right:10px;}
.zdxxzy_cont .summary{background:rgba(191,191,191,.1);padding:15px;font-size:12px;line-height:18px;color:rgba(0,0,0,.6);text-indent:0;}
.zdxxzy_cont a{text-align: center;font-size:12px;line-height:30px;color:#000;width: 100%;display: block;}
.zdxxzy_cont .xh{font-size:12px;line-height:30px;padding: 10px 0 0;}
.h5_footer{text-align: center;font-size:12px;line-height:60px;color:rgba(0,0,0,.26);}
.w-500{width: 100%;}
@media screen and (min-width:500px) {
  .w-500{width:500px;margin:0 auto;}
}
.button_group >>> .el-loading-mask{border-radius: 25px;}
.echarts_empty{height: 300px;}
a.more{text-align: center;font-size:14px;line-height:50px;color:#000;width: 100%;display: block;}
</style>